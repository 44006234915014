import React, { useEffect, useState } from "react";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import { FaPlusCircle, FaTrash } from "react-icons/fa";
import Select from "react-select";
import TopShowMessage from "../component/TopShowMessage";
import { useHistory, useLocation } from "react-router-dom";
import Swal from "sweetalert2";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import { useDebounce } from "use-debounce";
import { Typeahead } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";
import InventoryModalForm from "./InventoryModalForm";
import { maxWidth } from "@mui/system";
import InventoryTable from "./InventoryTable";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    minWidth: "50%",
    maxWidth:"90%",
  },
};

const AddInventory = () => {
  const history = useHistory();
  const userRole = localStorage.getItem("USER_ROLE");
  const location = useLocation();
  const [searchText, setSearchText] = useState("");
  const [searchValue] = useDebounce(searchText, 1000);
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [inventoryData, setInventoryData] = useState([]);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [formShowData, setFormShowData] = useState({
    warehouseId: "",
    warehouseName: "",
    pincode: "",
    city: "",
    state: "",
    address: "",
  });


  const updateOrderStatus = async (id) => {
    setIsLoadingOn(true);
    const tempData = await getData(`warehouse/getInventory?warehouseId=${id}`);
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setInventoryData(tempData.data[0]?.inventory);
      const item = tempData.data[0];
      setFormShowData({
        warehouseName: item.warehouseName,
        pincode: item.pincode,
        city: item.city,
        state: item.state,
        address: item.address,
        warehouseId: item.warehouseId,
        isDelete: item.isDeleted,
      });
    }
  };

  useEffect(() => {
    if (id) {
      updateOrderStatus(id);
    }
  }, [id]);


  const statusModalOpen = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };


  return (
    <>
      {isLoadingOn && <Loader />}
      <div className="productData">
          <h4>
            <b>Warehouse Details</b>
          </h4>
        <div className="formsec">
          <div className="row">
            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="warehouseId" className="form-label">
                  Warehouse Id<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control"
                  id="warehouseId"
                  name="warehouseId"
                  value={formShowData.warehouseId}
                  required
                />
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="warehouseName" className="form-label">
                  Warehouse Name<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control"
                  id="warehouseName"
                  name="warehouseName"
                  value={formShowData.warehouseName}
                  required
                />
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="pincode" className="form-label">
                  Pincode<span>*</span>
                </label>
                <input
                  type="number"
                  placeholder="Enter Pincode"
                  className="form-control"
                  id="pincode"
                  name="pincode"
                  value={formShowData.pincode}
                  required
                />
              </div>
            </div>

            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="city" className="form-label">
                  City<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter City"
                  className="form-control"
                  id="city"
                  name="city"
                  value={formShowData.city}
                  required
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-3 changeProductPro">
              <div className="mb-3">
                <label htmlFor="state" className="form-label">
                  State<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter State"
                  className="form-control"
                  id="state"
                  name="state"
                  value={formShowData.state}
                  required
                />
              </div>
            </div>

            <div className="col-md-6 changeProductPro">
              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Address<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Address"
                  className="form-control"
                  id="address"
                  name="address"
                  value={formShowData.address}
                  required
                />
              </div>
            </div>
          </div>
        </div>

        <div className="inventoryDataSection">
        <div className="updateInventory">
          <button onClick={statusModalOpen} className="btn btn-success">
            Add Inventory
          </button>
        </div>
          <InventoryTable
            data={inventoryData}
            userRole={userRole}
            statusModalOpen={statusModalOpen}
          />
        </div>

        <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <button className="modalCloseBtn" onClick={closeModal}>
            <FaRegTimesCircle />
          </button>
          {isLoadingOn ? <Loader /> : ""}
          <h4 style={{marginTop:15, marginLeft:20,}}>Inventory Details</h4>
          <div className="modalUserPaymentStatus">
            <InventoryModalForm
              closeModal={closeModal}
              updateOrderStatus={() => updateOrderStatus(id)}
            />
          </div>
        </Modal>
      </div>
    </>
  );
};

export default AddInventory;
