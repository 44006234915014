import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { FaSearch } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import "../css/makeTable.css";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import { BsFillHouseAddFill } from "react-icons/bs";
import Swal from "sweetalert2";
import WarehouseTable from "./WarehouseTable";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    minWidth: "30%",
  },
};

function WarehouseList() {
  const userRole = localStorage.getItem("USER_ROLE");
  const [merchantUserData, setMerchantUserData] = useState();
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [formData, setFormData] = useState({
    warehouseName: "",
    pincode: "",
    city: "",
    state: "",
    address: "",
  });

  const handleInputChange = (e) => {
    if (merchantRefrenceType === "userId" || "mobileNumber") {
      const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
      setSearchBoxValue(value);
    } else {
      setSearchBoxValue(e.target.value);
    }
  };
  const inputRef = useRef();

  const statusModalOpen = (item, isEdit = false) => {
    if (isEdit) {
      setFormData({
        warehouseName: item.warehouseName,
        pincode: item.pincode,
        city: item.city,
        state: item.state,
        address: item.address,
        warehouseId: item.warehouseId,
        isDelete: item.isDeleted,
      });
      setSelectedOptionStatus({
        value: item.isDeleted === "No" ? "Active" : "Inactive",
        label: item.isDeleted === "No" ? "Active" : "Inactive",
      });
    } else {
      setFormData({
        warehouseName: "",
        pincode: "",
        city: "",
        state: "",
        address: "",
      });
      setSelectedOptionStatus(null);
    }
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const resetFilters = () => {
    inputRef.current.value = "";
    setMerchantRefrenceType("");
    setSearchBoxValue("");
    updateOrderStatus();
    setSelectedOptionStatus(null);
  };

  const searchManualData = async () => {
    let filterData = {};

    if (merchantRefrenceType === "userId") {
      filterData = {
        merchantId: searchBoxValue,
      };
    } else if (merchantRefrenceType === "mobileNumber") {
      filterData = {
        mobileNumber: searchBoxValue,
      };
    } else {
      alert("Please select any one option");
    }

    setIsLoadingOn(true);

    const queryParams = new URLSearchParams(filterData).toString();

    const tempData = await getData(`warehouse/getWarehouse?${queryParams}`);

    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setMerchantUserData(tempData.data.records);
    }
  };

  const history = useHistory();

  const updateOrderStatus = async () => {
    setIsLoadingOn(true);
    const tempData = await getData("warehouse/getWarehouse");
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      setMerchantUserData(tempData.data);
    }
  };

  const handlePinCodeChange = async (value) => {
    if (value.length === 6) {
      setIsLoadingOn(true);
      let res = await getData(`users/getCityStateByPincode/${value}`);
      setIsLoadingOn(false);
      if (res.statusCode === 200) {
        const { district, stateName } = res.data[0];
        setFormData((prevData) => ({
          ...prevData,
          city: district,
          state: stateName,
        }));
      } else {
        alert("Error", "Invalid Pin Code");
      }
    }
  };

  useEffect(() => {
    updateOrderStatus();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingOn(true);
    const res = await postData(`warehouse/createWarehouse`, formData);
    setIsLoadingOn(false);
    if (res.statusCode === 200) {
      closeModal();
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data updated successfully!",
      });
      updateOrderStatus();
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Something went wrong!",
      });
    }
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setIsLoadingOn(true);
    const res = await postData(`warehouse/updateWareHouse`, formData);
    setIsLoadingOn(false);
    if (res.statusCode === 200) {
      closeModal();
      Swal.fire({
        icon: "success",
        title: "Success!",
        text: "Data updated successfully!",
      });
      updateOrderStatus();
    } else {
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Something went wrong!",
      });
    }
  };

  const handleUpdateChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    if (name === "pincode") {
      handlePinCodeChange(value);
    }
  };

  const merchantOption = [
    { value: "Warehouse Id", label: "Warehouse Id" },
    { value: "Warehouse Name", label: "Warehouse Name" },
  ];

  return (
    <div>
      {isLoadingOn ? <Loader /> : ""}
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-2">
              <Select
                placeholder="Select Search"
                name="role"
                options={merchantOption}
                value={selectedOptionStatus}
                onChange={(e) => {
                  setMerchantRefrenceType(e.value);
                  setSelectedOptionStatus(e);
                }}
              />
            </div>
            <div className="col-md-2">
              <input
                type={
                  merchantRefrenceType === "userId" || "mobileNumber"
                    ? "tel"
                    : "text"
                }
                className="searchInputField"
                ref={inputRef}
                onChange={handleInputChange}
                placeholder="Enter Details"
                value={searchBoxValue}
              />
            </div>
            <div className="col-md-2 text-center">
              <button className="searchTableData" onClick={searchManualData}>
                <FaSearch /> Search
              </button>
            </div>

            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>
            <div className="col-md-4 text-end" onClick={statusModalOpen}>
              <button className="resetTableData">
                <BsFillHouseAddFill /> Add Warehouse
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="orderListDataFooter">
        {merchantUserData && (
          <WarehouseTable
            data={merchantUserData}
            userRole={userRole}
            statusModalOpen={statusModalOpen}
          />
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        {isLoadingOn ? <Loader /> : ""}
        <h4 className="pleaseSelectStatus">Warehouse Details</h4>
        <div className="modalUserPaymentStatus">
          <form
            onSubmit={formData?.warehouseId ? handleEditSubmit : handleSubmit}
            className="formsec"
          >
            <div className="group groupLabelChange">
              <div className="mb-3">
                <label htmlFor="warehouseName" className="form-label">
                  Warehouse Name<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Name"
                  className="form-control"
                  id="warehouseName"
                  name="warehouseName"
                  value={formData.warehouseName}
                  onChange={handleUpdateChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="pincode" className="form-label">
                  Pincode<span>*</span>
                </label>
                <input
                  type="number"
                  placeholder="Enter Pincode"
                  className="form-control"
                  id="pincode"
                  name="pincode"
                  value={formData.pincode}
                  onChange={handleUpdateChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="city" className="form-label">
                  City<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter City"
                  className="form-control"
                  id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleUpdateChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="state" className="form-label">
                  State<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter State"
                  className="form-control"
                  id="state"
                  name="state"
                  value={formData.state}
                  onChange={handleUpdateChange}
                  required
                />
              </div>

              <div className="mb-3">
                <label htmlFor="address" className="form-label">
                  Address<span>*</span>
                </label>
                <input
                  type="text"
                  placeholder="Enter Address"
                  className="form-control"
                  id="address"
                  name="address"
                  value={formData.address}
                  onChange={handleUpdateChange}
                  required
                />
              </div>

              {formData?.warehouseId && (
                <div className="mb-3">
                  <label htmlFor="state" className="form-label">
                    Status<span>*</span>
                  </label>
                  <Select
                    placeholder="Select Status"
                    name="isDelete"
                    options={[
                      { value: "No", label: "Active" },
                      { value: "Yes", label: "Inactive" },
                    ]}
                    value={selectedOptionStatus}
                    onChange={(e) => {
                      setFormData((prevData) => ({
                        ...prevData,
                        isDelete: e.value,
                      }));
                      setSelectedOptionStatus(e);
                    }}
                    required
                  />
                </div>
              )}
            </div>
            <div className="text-center mt-3">
              <button type="submit" className="btn btn-success">
                Submit
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default WarehouseList;
