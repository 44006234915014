import React, { useMemo } from "react";
import {
  useTable,
  useSortBy,
  usePagination,
} from "react-table";

const WarehouseTable = ({ data, userRole, statusModalOpen }) => {
  const columns = useMemo(
    () => [
      {
        Header: "Warehouse Id",
        accessor: "warehouseId",
      },
      {
        Header: "Warehouse Name",
        accessor: "warehouseName",
      },
      {
        Header: "Address",
        accessor: "address",
        Cell: (cell) => {
          return (
            <>
              <div className="text-center">
                {`${cell.row.original.address || "N/A"}, ${
                  cell.row.original.city || "N/A"
                }, ${cell.row.original.state || "N/A"}, ${
                  cell.row.original.pincode || "N/A"
                }`}
              </div>
            </>
          );
        },
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Status",
        accessor: "isDeleted",
        Cell: (cell) => {
          const status = cell.row.original.isDeleted;
          return (
            <>
              <div className="text-center">
                <button
                  style={{ border: "none" }}
                  className={`badge badge-pill
                              ${
                                status == "No"
                                  ? "badge-success"
                                  : status == "Active"
                                  ? "badge-warning"
                                  : "badge-danger"
                              }`}
                  value={cell.row.original.isDeleted}
                >
                  {cell.row.original.isDeleted == 'No' ? "Active" : "Inactive"}
                </button>
              </div>
            </>
          );
        },
      },
      {
        Header: "Action",
        accessor: "",
        Cell: ({ row }) => {
          return (
            <>
              <div
                className="loanIdDownloadBtnDiv d-flex justify-content-center align-items-center text-center"
                style={{ padding: "5px !important;" }}
              >
                <button
                  style={{ border: "none", marginRight: "8px" }}
                  className={`btn-sm btn-warning`}
                  onClick={() => {
                    if (userRole === "Admin" || userRole === "Operation") {
                      statusModalOpen(row.original, true);
                    }
                  }}
                >
                  <span>Edit</span>
                </button>

                <button
                  style={{ border: "none" }}
                  className={`btn-sm btn-success`}
                  onClick={() => {
                    if (userRole === "Admin" || userRole === "Operation") {
                      const { warehouseId } = row.original;
                      window.open(`/AddInventory?id=${warehouseId}`, "_blank");
                    }
                  }}
                >
                  <span>View</span>
                </button>
              </div>
            </>
          );
        },
      },
    ],
    [userRole, statusModalOpen]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    setPageSize, // Add setPageSize here
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy, // useSortBy should come before usePagination
    usePagination
  );

  return (
    <>
      <div>
        <table {...getTableProps()} className="dashboardTable">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className="tableHeader"
              >
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {/* Add a sort direction indicator */}
                    <span>
                      {column.isSorted ? (column.isSortedDesc ? "↓" : "↑") : ""}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()} className="tableTd">
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
          <tfoot className="orderTableFooter">
            {headerGroups.map((group) => (
              <tr {...group.getFooterGroupProps()}>
                {group.headers.map((column) => (
                  <td {...column.getFooterProps()}>
                    {column.render("Footer")}
                  </td>
                ))}
              </tr>
            ))}
          </tfoot>
        </table>
        <div className="row m-0">
          <div className="pagination col-lg-12">
            <div className="col-lg-2">
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {"<<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {"<"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {">"}
              </button>{" "}
              <button
                className="defaultPaginationButton"
                onClick={() => gotoPage(pageOptions.length - 1)}
                disabled={!canNextPage}
              >
                {">>"}
              </button>{" "}
            </div>
            <div className="col-lg-2">
              <span>
                Total Records <strong>{page.length}</strong>{" "}
              </span>
            </div>
            <div className="col-lg-2">
              <span>
                Page{" "}
                <strong>
                  {pageIndex + 1} of {pageOptions.length}
                </strong>{" "}
              </span>
            </div>
            <div className="col-lg-3">
              <span>
                Go to page{" "}
                <input
                  type="number"
                  defaultValue={pageIndex + 1}
                  onChange={(e) => {
                    const page = e.target.value
                      ? Number(e.target.value) - 1
                      : 0;
                    gotoPage(page);
                  }}
                  className="gotoPage"
                />
              </span>{" "}
            </div>
            <div className="col-lg-3">
              <select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
                className="selectDisplayNoOfRecords"
              >
                {[5, 10, 20, 30, 50].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                    Show {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarehouseTable;
