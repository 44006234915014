import React, { useEffect, useState, useRef, useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import { FaSearch } from "react-icons/fa";
import { HiRefresh } from "react-icons/hi";
import "../css/makeTable.css";
import Select from "react-select";
import { getData, postData } from "../store/utils";
import Loader from "../component/Loader";
import Modal from "react-modal";
import { FaRegTimesCircle } from "react-icons/fa";
import InventoryModalForm from "./InventoryModalForm";
import InventoryTable from "./InventoryTable";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    overflow: "none",
    padding: "0px",
    minWidth: "50%",
    maxWidth: "90%",
  },
};

function InventoryList() {
  const userRole = localStorage.getItem("USER_ROLE");
  const [merchantUserData, setMerchantUserData] = useState();
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [modalIsOpen, setIsOpen] = React.useState(false);

  const handleInputChange = (e) => {
    if (merchantRefrenceType === "userId" || "mobileNumber") {
      const value = e.target.value.replace(/[^0-9]/g, "").slice(0, 10);
      setSearchBoxValue(value);
    } else {
      setSearchBoxValue(e.target.value);
    }
  };
  const inputRef = useRef();

  const statusModalOpen = () => {
    setIsOpen(true);
  };

  const closeModal = () => {
    setIsOpen(false);
  };

  const resetFilters = () => {
    inputRef.current.value = "";
    setMerchantRefrenceType("");
    setSearchBoxValue("");
    updateOrderStatus();
    setSelectedOptionStatus(null);
  };

  const searchManualData = async () => {
    let filterData = {};

    if (merchantRefrenceType === "userId") {
      filterData = {
        merchantId: searchBoxValue,
      };
    } else if (merchantRefrenceType === "mobileNumber") {
      filterData = {
        mobileNumber: searchBoxValue,
      };
    } else {
      alert("Please select any one option");
    }

    setIsLoadingOn(true);

    const queryParams = new URLSearchParams(filterData).toString();

    const tempData = await getData(`warehouse/getInventory?${queryParams}`);

    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setMerchantUserData(tempData.data.records);
    }
  };


  const updateOrderStatus = async () => {
    setIsLoadingOn(true);
    const tempData = await getData("warehouse/getInventory");
    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      const tempInventoryData = [];
      for (let index = 0; index < tempData?.data?.length; index++) {
        const element = tempData?.data[index];
        if (element?.inventory) {
          tempInventoryData.push([...element?.inventory]);
        }
      }
      const flattenedData = tempInventoryData.flat();

      console.log("this is data", flattenedData);
      setMerchantUserData(flattenedData);
    }
  };

  useEffect(() => {
    updateOrderStatus();
  }, []);

  const merchantOption = [
    { value: "Warehouse Id", label: "Warehouse Id" },
    { value: "Warehouse Name", label: "Warehouse Name" },
  ];

  return (
    <div>
      {isLoadingOn ? <Loader /> : ""}
      <h4 style={{ marginBottom: 10 }}>Inventory List</h4>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-2">
              <Select
                placeholder="Select Search"
                name="role"
                options={merchantOption}
                value={selectedOptionStatus}
                onChange={(e) => {
                  setMerchantRefrenceType(e.value);
                  setSelectedOptionStatus(e);
                }}
              />
            </div>
            <div className="col-md-2">
              <input
                type={
                  merchantRefrenceType === "userId" || "mobileNumber"
                    ? "tel"
                    : "text"
                }
                className="searchInputField"
                ref={inputRef}
                onChange={handleInputChange}
                placeholder="Enter Details"
                value={searchBoxValue}
              />
            </div>
            <div className="col-md-2 text-center">
              <button className="searchTableData" onClick={searchManualData}>
                <FaSearch /> Search
              </button>
            </div>

            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>
            {/* <div className="col-md-4 text-end" onClick={statusModalOpen}>
              <button className="resetTableData">
                <BsFillHouseAddFill /> Add Warehouse
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="inventoryDataSection">
        {merchantUserData && (
          <InventoryTable
            data={merchantUserData}
            userRole={userRole}
            statusModalOpen={statusModalOpen}
          />
        )}
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <button className="modalCloseBtn" onClick={closeModal}>
          <FaRegTimesCircle />
        </button>
        {isLoadingOn ? <Loader /> : ""}
        <h4 className="pleaseSelectStatus">Warehouse Details</h4>
        <div className="modalUserPaymentStatus">
          <InventoryModalForm
            closeModal={closeModal}
            updateOrderStatus={() => updateOrderStatus()}
          />
        </div>
      </Modal>
    </div>
  );
}

export default InventoryList;
