import React, { useState, useMemo, useRef, useEffect } from 'react'
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
  useSortBy,
} from "react-table";
import Loader from "../component/Loader";
import "../css/makeTable.css";
import { getData, postData } from "../store/utils";
import Swal from "sweetalert2";
import { HiRefresh } from "react-icons/hi";
import Select from "react-select";
import { FaSearch, FaFileDownload } from "react-icons/fa";
import Table from "../pages/ReactTable"
import { useHistory } from "react-router-dom";
import { formatDateTimeSecond } from '../component/common.js';

const LeadMerchantList = () => {
  const [leadMerchantData, setLeadMerchantData] = useState();
  const [isLoadingOn, setIsLoadingOn] = useState(false);
  const [selectedOptionStatus, setSelectedOptionStatus] = useState(null);
  const [merchantRefrenceType, setMerchantRefrenceType] = useState();
  const [searchBoxValue, setSearchBoxValue] = useState();
  const [pageCount, setPageCount] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [errorMessage, setErrorMessage] = useState("");
  const [pageIndex, setPageIndex] = useState(0); // Page index starts from 0
  const [pageSize, setPageSize] = useState(10); // Default page size
  const [sortBy, setSortBy] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [subCategoryName, setSubCategoryName] = useState("");


  const inputRef = useRef();
  const history = useHistory();
  const getLeadMerchantList = async (page = 0, pageSize = 10, sortBy = "", orderBy = "") => {

    const filterData = {
      page: page + 1,
      pageSize: pageSize,
    }
    setIsLoadingOn(true);
    // let apiUrl = `merchantLead/leadMerchant/1?page=${page + 1}&pageSize=${pageSize}`;

    // if (sortBy && orderBy) {
    //     apiUrl += `&sortBy=${sortBy}&orderBy=${orderBy}`;
    // }
    // const tempData = await postData(apiUrl)
    const tempData = await postData(`merchantLead/leadMerchant`, filterData)

    setIsLoadingOn(false);
    if (tempData.statusCode == 200) {
      const merchantLead = tempData?.data?.data || []
      setLeadMerchantData(merchantLead);
      setPageCount(tempData?.data?.pagination?.totalPagesCount);
      setTotalRecords(tempData?.data?.pagination?.totalRecords);
      setErrorMessage("");
    }
    else {
      Swal.fire({
        title: "Error!",
        text: tempData.data.errors.msg || "Something went wrong",
      })
    }
  };

  useEffect(() => {
    getLeadMerchantList(pageIndex, pageSize, sortBy, orderBy);
  }, [pageIndex, pageSize, sortBy, orderBy]);

  const downloadDumpData = async () => {
    setIsLoadingOn(true);
    const tempData = await getData("users/getExcelLeadMerchant/nothing");
    setIsLoadingOn(false);
    if (tempData.statusCode === 200) {
      const downloadLink = document.createElement("a");
      downloadLink.href = tempData.data.Location;
      downloadLink.target = "_blank";
      downloadLink.rel = "noopener noreferrer";
      downloadLink.click();
    }
  };

  const displayDistributorInfo = (leadId) => {
    const newTab = window.open("", "_blank");
    if (newTab) {
      newTab.location = "/LeadMerchantDetails?leadId=" + leadId;
    } else {
      history.push({
        pathname: "/LeadMerchantDetails",
        state: { leadId: leadId },
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Sr. No.",
        accessor: (row, i) => i + 1 + pageIndex * pageSize,
        Cell: ({ cell }) => (
          <span style={{ fontWeight: "600", fontSize: "0.875rem" }}>{cell.row.index + 1 + pageIndex * pageSize}.</span>
        ),
      },
      {
        Header: "Lead Ref Number",
        accessor: "id",
        Cell: ({ cell }) => {
          return (
            <>
              <div className="loanIdDownloadBtnDiv">
                <button
                  className="btn btn-sm someprobtn view-log-detail loanTblDownloadLoanIdBtn"
                  title="View details"
                  value={cell?.row?.original?.leadId}
                  onClick={() => displayDistributorInfo(cell?.row?.original?.leadId)}
                >
                  <span style={{ fontWeight: "600" }}>
                    {"L-" + cell?.row?.original?.leadId}
                  </span>
                </button>
              </div>
            </>
          );
        },
        filter: "fuzzyText",
        disableSortBy: true,
        disableFilters: false,
      },
      {
        Header: "Shop Name",
        accessor: "shopName",
      },
      {
        Header: "Merchant Mobile",
        accessor: "merchantMobile",
      },
      {
        Header: "Business Type",
        accessor: "businessType",
      },
      {
        Header: "Employee Name",
        accessor: "employeeName",
      },
      {
        Header: "Employee Mobile",
        accessor: "employeeMobile",
      },
      {
        Header: "Lead Type",
        accessor: "leadType",
      },
      {
        Header: "Next Visit Date",
        accessor: "nextVisitDate",
      },
      {
        Header: "Lead Created At",
        accessor: "createdAt",
        Cell: ({ cell }) => {
          return (
            <>
              <div>
                {formatDateTimeSecond(cell?.row?.original?.createdAt)}
              </div>
            </>
          );
        },
      },
    ],
    [pageIndex, pageSize]
  );

  const merchantOption = [
    { value: "employeeName", label: "Employee Name" },
    { value: "employeeMobile", label: "Employee Mobile" },
    { value: "merchantName", label: "Merchant Name" },
    { value: "merchantMobile", label: "Merchant Mobile" },

    // { value: "MobileNumber", label: "Mobile Number" },
    // { value: "email", label: "Email" },
    // { value: "shopType", label: "Shop Type" },
    // { value: "shopName", label: "Shop Name" },
  ];
  const resetFilters = () => {
    inputRef.current.value = "";
    setMerchantRefrenceType("");
    setSearchBoxValue("");
    getLeadMerchantList();
    setSelectedOptionStatus(null);
  };

  const handleInputChange = (e) => {
    if (merchantRefrenceType === 'Mobile Number') {
      const value = e.target.value.replace(/[^0-9]/g, '').slice(0, 10);
      setSearchBoxValue(value);
    } else {
      setSearchBoxValue(e.target.value);
    }
  };

  const handleGotoPage = (page) => {
    if (page < 0 || page >= pageCount) {
      setErrorMessage(`No data to display`);
    } else {
      setErrorMessage("");
      setPageIndex(page);
    }
  };

  const handleSort = (column) => {
    let newOrderBy = "asc";

    if (sortBy === column.id) {
      newOrderBy = orderBy === "asc" ? "desc" : "asc";
    }
    setSortBy(column.id);
    setOrderBy(newOrderBy);
    getLeadMerchantList(pageIndex, pageSize, column.id, newOrderBy);
  };

  const searchManualData = async () => {
    let filterData = {};

    if (merchantRefrenceType === "employeeName") {
      filterData = {
        employeeName: searchBoxValue,
      };
    } if (merchantRefrenceType === "employeeMobile") {
      filterData = {
        employeeMobile: searchBoxValue,
      };
    } if (merchantRefrenceType === "merchantName") {
      filterData = {
        merchantName: searchBoxValue,
      };
    } if (merchantRefrenceType === "merchantMobile") {
      filterData = {
        merchantMobile: searchBoxValue,
      };
    }
    else {
      // alert("Please select any one option");
    }

    const filterDataPayload = {
      page: 1,
      pageSize: pageSize,
      employeeName: filterData?.employeeName,
      employeeMobile: filterData?.employeeMobile,
      merchantName: filterData?.merchantName,
      merchantMobile: filterData?.merchantMobile,
      leadId: "",
    }

    setIsLoadingOn(true);
    const tempData = await postData(`merchantLead/leadMerchant`, filterDataPayload)

    setIsLoadingOn(false);

    if (tempData.statusCode === 200) {
      setLeadMerchantData(tempData?.data?.data);
    } else {
      Swal.fire({
        title: "Error!",
        text: tempData?.data?.errors?.msg || "Something went wrong",
      });
    }
  };

  return (
    <>
      {isLoadingOn ? <Loader /> : ""}
      <h3 className="tableHeaderMsg">Lead Merchant List</h3>
      <div className="customHeaderTable">
        <div className="container-fluid">
          <div className="row alignmentTable">
            <div className="col-md-2">
              <Select
                placeholder="Select Search"
                name="product"
                options={merchantOption}
                value={selectedOptionStatus}
                onChange={(e) => {
                  setMerchantRefrenceType(e.value);
                  setSelectedOptionStatus(e);
                }}
              />
            </div>
            <div className="col-md-2">
              <input
                type={merchantRefrenceType === "Mobile Number" ? "tel" : "text"}
                className="searchInputField"
                ref={inputRef}
                // onChange={(e) => {
                //     setSearchBoxValue(e.target.value);
                // }}
                onChange={handleInputChange}
                placeholder="Enter Details"
                value={searchBoxValue}
              />
            </div>
            <div className="col-md-2 text-center">
              <button className="searchTableData" onClick={searchManualData}>
                <FaSearch /> Search
              </button>
            </div>

            <div className="col-md-2 text-center" onClick={resetFilters}>
              <button className="resetTableData">
                <HiRefresh /> Reset
              </button>
            </div>

            <div className="col-md-3 pl-0 text-end">
              <button
                onClick={downloadDumpData}
                id="CSV"
                type="button"
                className="font-weight-bold download-csv transformBtn acceptDataCss"
              >
                <a href="javascript:void(0)">
                  <FaFileDownload />
                </a>
              </button>
            </div>

          </div>
        </div>
      </div>
      <div className="orderListDataFooter">
        {leadMerchantData ? (
          <Table
            columns={columns}
            data={leadMerchantData}
            pageCount={pageCount}
            pageIndex={pageIndex}
            pageSize={pageSize}
            setPageIndex={setPageIndex}
            setPageSize={setPageSize}
            totalRecords={totalRecords}
            handleGotoPage={handleGotoPage}
            errorMessage={errorMessage}
            handleSort={handleSort}
            sortBy={sortBy}
            orderBy={orderBy}
          />
        ) : (
          <div style={{fontWeight:"bold"}}>No data available</div> // Render a fallback message
        )}
      </div>
    </>
  )
}

export default LeadMerchantList
